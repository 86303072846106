import ReactPaginate from 'react-paginate'

//images
import prevPageIcon from '../../assets/images/scraper/icons/prev-arrow.png'
import nextPageIcon from '../../assets/images/scraper/icons/next-arrow.png'

const Pagination = props => {
    const { pages, page, handlePage, } = props

    return (
        pages > 1 ?
            <ReactPaginate
                previousLabel={<img src={prevPageIcon} />}
                nextLabel={<img src={nextPageIcon} />}
                breakLabel={'...'}
                pageCount={pages}
                initialPage={page - 1}
                forcePage={page - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePage}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                disableInitialCallback={true}
            />
            :
            <>
            </>
    )
}

export default Pagination