import { useState, useEffect } from 'react'

//mui components
import { Tooltip, TextField, InputAdornment, Button, MenuItem, Select, InputLabel, ClickAwayListener } from '@mui/material'

//partials
import PublishedProductRow from "./PublishedProductRow"
import Pagination from '../../../partials/Pagination'

//helpers
import Api from '../../../../helpers/Api'

//images
import loader from '../../../../assets/images/scraper/loading.svg'
import settingsIcon from '../../../../assets/images/scraper/icons/settings.png'

//providers
import { useAppContext } from '../../../../providers/App'

let timeout2
const PublishedProducts = () => {
    const app = useAppContext()
    
    const [state, setState] = useState({
        filter: {
            banned    : 0,
            name      : '',
            page      : 1,
        },
        updateParams: {
            from_page : 0,
            to_page   : 0
        },
        success       : false,
        error         : false,
        loading       : true,
        data: {
            items     : [],
            pages     : 1,
        },
        editedData    : {},
        showGroupTax  : false,
        showUpdateRange: false,
        confirmDelete : false
    })

    /**
     * 
     * @param {object} e референция към полето, от което е извикана функцията 
     */
     const handleChange = (e, id) => {
        const { name, value }   = e.target

        setState(prev => ({
            ...prev,
            editedData: {
                ...prev.editedData,
                [name]    : value
                // [id]: {
                //     ...prev.editedData[id],
                //     [name]    : value
                // }
            }
        }))
    }

    //TODO
    //При смяна на филтъра да се извика handlePage(1), за да може заявката да връща резуллтати от 1ва страница.
    const handleChangeFilter = e => {
        let { name, value }   = e.target

        if ((Number(value) === 4)) {
            name              = 'type'
            value             = 'amazon'
        } else if ((Number(value) === 5)) {
            name              = 'type'
            value             = 'aliexpress'
        }

        clearTimeout(timeout2)
        timeout2 = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    type      : null,
                    banned    : null,
                    [name]    : value
                }
            }))
        }, 300)
    }

    const handleTax = (e, data) => {
        const { value }   = e.target
        const { id }      = data

        handleChange(e, id)
        calcAndApplyTax(value, data)
    }

    const calcAndApplyTax = (tax, data) => {
        const { id, amazon_price }  = data
        const updatedTax            = Number(amazon_price) + (Number(amazon_price) * (0.01 * Number(tax)))

        setState(prev => ({
            ...prev,
            editedData: {
                ...prev.editedData,
                [id]: {
                    ...prev.editedData[id],
                    tax             : updatedTax,
                }
            }
        }))
    }

    const handleQty = (e, data) => {
        const { value, name }   = e.target
        const { id }            = data

        applyQty(value, name)
    }

    const applyQty = (value, name) => {
        setState(prev => ({
            ...prev,
            editedData: {
                ...prev.editedData,
                [name]: value
            }
        }))
    }

    const viewFilter = () => {
        setState(prev => ({
            ...prev,
            viewFilter: !prev.viewFilter
        }))
    }

    useEffect(() => {
        getProducts()
    }, [state.filter])
   
    const productsUrl = `status=published&visible=1&name=${state.filter.name}${state.filter.type ? `&type=${state.filter.type}` : ''}${Number(state.filter.banned) === 1 ? '&banned=1' : ''}${Number(state.filter.banned) === 3 ? '&with_error=1' : ''}&page=${state.filter.page}`
    
    const getProducts = () => {
        setLoading(true)

        Api.get(`list?${productsUrl}`)
            .then(res => {
                setProducts(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setProducts = data => {
        setState(prev => ({
            ...prev,
            data
        }))
    }

    /**
     * 
     * @param {boolean} success дали да се визуализира успех
     */
    const handleSuccess = success => {
        setState(prev => ({
            ...prev,
            success
        }))

        if (state.error) handleError(false)
    }

    /**
     * 
     * @param {boolean} error дали да се визуализира грешка
     */
    const handleError = error => {
        setState(prev => ({
            ...prev,
            error
        }))

        if (state.success) handleSuccess(false)
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))

        if (state.success) handleSuccess(false)
        if (state.error) handleError(false)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1
            }
        }))
    }

    /**
     * 
     * @param {string} id id на продукт 
     */
    const handleRemoveProduct = id => {
        Api.post('delete', {
            id
        })
            .then(() => {
                getProducts()
            })
    }

    const handleUpdate = () => {
        if(state.loading) return

        setLoading(true)
        const data    = new FormData()
        Object.keys(state.editedData).map((k, i) => {
            data.append(`data${k}`, Object.values(state.editedData)[i])
        })

        data.append('status', 'published')
        data.append('visible', '1')
        data.append('name', state.filter.name)
        // data.append('page', state.filter.page)
        if(state.updateParams.from_page) data.append('from_page', state.updateParams.from_page)
        if(state.updateParams.to_page) data.append('to_page', state.updateParams.to_page)
        if(Number(state.filter.banned)) data.append('banned', 1)

        Api.post('mass-update-and-sync', data)
        .then(() => {
            getProducts()
            app.showMessage('Действието беше успешно!', 'success')
        })
    }

    //todo заявка + groupTax 
    const handleAddGroupTax = () => {
        setShowGroupTax(false)
        const groupTax = state.editedData.group_tax

        const data = new FormData()
        if(Number(groupTax)) data.append('tax', groupTax)
        else return

        if(state.showUpdateRange){
            if(state.updateParams.from_page) data.append('from_page', state.updateParams.from_page)
            if(state.updateParams.to_page) data.append('to_page', state.updateParams.to_page)    
        }
        
        setLoading(true)
        Api.post(`mass-update-profit-percent?${productsUrl}`, data)
            .then(() => {
                getProducts()
                app.showMessage('Действието беше успешно!', 'success')
            })
    }

    const setShowGroupTax = showGroupTax => {
        setState(prev => ({
            ...prev,
            showGroupTax
        }))
    }

    const setUpdateRange = showUpdateRange => {
        setState(prev => ({
            ...prev,
            showUpdateRange
        }))
    }

    const handleChangeUpdatePrams = e => {
        const { name, value } = e.target
        
        setState(prev => ({
            ...prev,
            updateParams: {
                ...prev.updateParams,
                [name]: value
            }
        }))
    }

    const setConfirmDelete = confirmDelete => {
        setState(prev => ({
            ...prev,
            confirmDelete
        }))
    }
    const handleMassDelete = () => {
        if(!state.confirmDelete){
            setConfirmDelete(true)
            return
        } 

        setLoading(true)
        Api.post(`mass-delete?${productsUrl}`)
            .then(res => {
                getProducts()
                app.showMessage(`${res.data.deleted} aртикули бяха премахнати успешно!`, 'success')
            })
            .finally(() => {
                setConfirmDelete(false)
            })
    }

    return (
        <aside className="panel">
            <div className="head">
                <h2>
                    3. Публикувани артикули (<span className="color" style={{ backgroundColor: app.getCurrentPlatformColor(0) }}></span>AMAZON и <span className="color" style={{ marginLeft: '5px', backgroundColor: app.getCurrentPlatformColor(1) }}></span>AliExpress)
                </h2>
                <Tooltip title="Управление на филтъра">
                    <span
                        onClick={viewFilter}
                        className="link"
                    >
                        {state.viewFilter ?
                            'Скриване на филтър'
                            :
                            'Показване на филтър'
                        }
                    </span>
                </Tooltip>
            </div>
            <hr />
            {state.viewFilter ?
                <div className="row filter">
                    <div className="col">
                        <TextField
                            label="Име на артикул"
                            type="text"
                            name="name"
                            // value={state.filter.name}
                            onChange={handleChangeFilter}
                            variant="standard"
                            className="name"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"> </InputAdornment>,
                            }}
                            placeholder="Търсене..."
                        />
                    </div>
                    <div
                        className="col"
                        style={{
                            maxWidth: '200px'
                        }}
                    >
                        <InputLabel id="demo-simple-select-label-5" className="select-label">Филтриране по</InputLabel>
                        <Select
                            variant="standard"
                            label="Филтриране по"
                            labelId="demo-simple-select-label-5"
                            id="demo-simple-select"
                            onChange={handleChangeFilter}
                            name="banned"
                            defaultValue={state.filter.banned}
                        >
                            <MenuItem value="0">Всички</MenuItem>
                            <MenuItem value="1">Неактуализиращи се</MenuItem>
                            <MenuItem value="3">Непубликувани</MenuItem>
                            <MenuItem value="4">Само от Amazon</MenuItem>
                            <MenuItem value="5">Само от AliExpress</MenuItem>
                            {/* <MenuItem value="price-asc">Най-висока цена</MenuItem>
                            <MenuItem value="price-desc">Най-ниска цена</MenuItem> */}
                        </Select>
                    </div>
                </div>
                :
                <>
                </>
            }
            {state.success ?
                <div className="success">
                    Артикулите бяха успешно качени.
                </div>
                :
                <>
                </>
            }
            {state.error ?
                <div className="errpr">
                    Нещо се обърка!
                </div>
                :
                <>
                </>
            }
            {state.loading ?
                <img src={loader} className="loading" />
                :
                state.data.items[0] ?
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Артиикул
                                </th>
                                <th className="text-right">
                                    Цена
                                </th>
                                <th>
                                    % Оскъп.
                                </th>
                                <th className="text-right">
                                    Кр. цена
                                </th>
                                <th>
                                    Кол.
                                </th>
                                <th className="options text-center">
                                    Опции
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.data.items.map(i =>
                                <PublishedProductRow
                                    key={i.id}
                                    handleTax={handleTax}
                                    handleQty={handleQty}
                                    handleRemoveProduct={handleRemoveProduct}
                                    editedData={state.editedData[i.id]}
                                    i={i}
                                    value={state[`[${i.id}][tax]`]}
                                />
                            )}
                        </tbody>
                    </table>
                    :
                    <p className="no-data">
                        Все още няма публикувани артикули.
                    </p>
            }
            <div className="buttons align-center">
                <Pagination
                    page={state.filter.page}
                    pages={state.data.pages}
                    handlePage={handlePage}
                />
                <div className="row align-center">
                    <Tooltip title="Премахване на артикулите от списъка">
                        <ClickAwayListener
                            onClickAway={() => {
                                setConfirmDelete(false)
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleMassDelete}
                                color="error"
                                disabled={!state.data?.items?.length || state.loading}
                            >
                                {state.confirmDelete ? 'Потвърждавам' : 'Изтриване'}
                            </Button>
                        </ClickAwayListener>
                    </Tooltip>
                    {state.showGroupTax ?
                        <ClickAwayListener
                            onClickAway={() => {
                                setShowGroupTax(false)
                            }}
                        >
                            <TextField
                                type="number"
                                variant="standard"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }}
                                style={{
                                    width: '100px',
                                    marginTop: '10px',
                                    marginLeft: '10px'
                                }}
                                autoFocus
                                name="group_tax"
                                onChange={handleChange}
                            />
                        </ClickAwayListener>
                        :
                        <>
                        </>
                    }
                    <Tooltip title="Добавяне на групова надценка на всички артикуи от списъка">
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (!state.showGroupTax) setShowGroupTax(true)
                                else handleAddGroupTax()
                            }}
                            color="secondary"
                            disabled={!state.data?.items?.length || state.loading}
                        >
                            {!state.showGroupTax ? 'Надценка' : 'Добавяне'}
                        </Button>
                    </Tooltip>

                    <Tooltip title="Синхронизиране на всички продукти от списъка с тези в Amazon и AliExpress">
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!state.data?.items?.length || state.loading}
                            onClick={handleUpdate}
                        >
                            Актуализация
                        </Button>
                    </Tooltip>
                    {state.showUpdateRange ?
                        <>
                            <TextField
                                type="number"
                                variant="standard"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">От</InputAdornment>,
                                }}
                                style={{
                                    width: '100px',
                                    marginTop: '10px',
                                    marginLeft: '10px'
                                }}
                                autoFocus
                                name="from_page"
                                onChange={handleChangeUpdatePrams}
                                value={state.updateParams.from_page}
                            />
                            <TextField
                                type="number"
                                variant="standard"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">До</InputAdornment>,
                                }}
                                style={{
                                    width: '100px',
                                    marginTop: '10px',
                                    marginLeft: '10px'
                                }}
                                name="to_page"
                                onChange={handleChangeUpdatePrams}
                                value={state.updateParams.to_page}
                            />
                        </>
                        :
                        <>
                        </>
                    }
                    <Tooltip title="Избор на страници, които да се синхронизират  с Amazon и AliExpress">
                        <Button 
                            className="settings-btn"
                            onClick={() => {
                                setUpdateRange(!state.showUpdateRange)
                            }}
                            disabled={!state.data?.items?.length || state.loading}
                            >
                            <img src={settingsIcon} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </aside>
    )
}

export default PublishedProducts