import { useRef } from 'react'
import { Link } from 'react-router-dom'

//mui components
import { Tooltip } from '@mui/material'

//providers
import { useAuthDataContext } from '../../../../providers/Auth'

//modals
import SettingsModal from '../../../../components/modals/Settings'

const Header = () => {
    const auth                = useAuthDataContext()
    const settingsModalRef    = useRef(null)

    return (
        <header>
            <SettingsModal
                ref={settingsModalRef}
            />
            <div className="wrapper">
                <Tooltip title="Към началния екран">
                    <Link to="/" className="brand">
                        Scraper
                        <span>
                            ®
                        </span>
                    </Link>
                </Tooltip>
                <nav>
                    {auth.isLogged() ?
                        <>
                            <Tooltip title="Общи настройки">
                                <span
                                    onClick={() => {
                                        settingsModalRef.current.open()
                                    }}
                                >
                                    Настройки
                                </span>
                            </Tooltip>
                            <Tooltip title="Излезте от профила">
                                <Link
                                    to="/"
                                    onClick={auth.handleLogout}
                                >
                                    Изход
                                </Link>
                            </Tooltip>
                        </>
                        :
                        <>
                        </>
                    }
                </nav>
            </div>
        </header>
    )
}

export default Header