import axios from 'axios'

const Api = axios.create({
    baseURL: process.env.REACT_APP_URL
})

Api.interceptors.request.use(config => {
    let token

    try {
        const auth = localStorage.getItem('auth')
        token = auth ? JSON.parse(auth).data?.access_token : ''
    } catch (e) {

    }

    config.params ||= {}

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
})


export default Api