import { useEffect } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'

//providers
import { useAppContext } from "./providers/App"
import { useAuthDataContext } from './providers/Auth'

//helpers
import Api from './helpers/Api'

//pages
import Login from './components/pages/auth/Login'
import Verify from './components/pages/auth/Verify'

import Settings from './components/pages/settings/Index'

import Scraper from './components/pages/scraper/Index'

import E404 from './components/pages/errors/404'

//partials
import Message from './components/partials/Message'

//styles
import './assets/scss/app.scss'

const App = () => {
  const app         = useAppContext()
  const auth        = useAuthDataContext()
  const location    = useLocation()

  if (location.pathname.includes('ebay-auth')){
    return <Navigate to   = {`/${location.search}`} />
  }

  /**
   * 
   * @returns основната страница на база стейта на профила
   */
  const renderView = () => {
    let view    = <></>

    if (!auth.isLogged()) {
      view      = <Login />
    } else if (!auth.isVerified()) {
      view      = <Verify />
    } else if (!auth.isConfigured() || app.aditionalSettings) {
      view      = <Settings />
    } else {
      view      = <Scraper />
    }

    return view
  }

  return (
    <Routes>
      <Route path="/" element={<><Message /> {renderView()}</>} />
      <Route path="*" element={<E404 />} />
    </Routes>
  )
}

export default App
