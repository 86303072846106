import { useState } from 'react'

//mui components
import { Tooltip, Button, Checkbox, FormControlLabel } from '@mui/material'

//images
import uploadIcon from '../../../../assets/images/scraper/icons/upload.png'
import loader from '../../../../assets/images/scraper/loading.svg'

//helpers
import Api from '../../../../helpers/Api'

//providers
import { useAppContext } from '../../../../providers/App'

const UploadFile = props => {
    const app = useAppContext()
    const { handleRefreshCurrentProducts } = props
    const [state, setState] = useState({
        file    : null,
        success : false,
        error   : false,
        loading : false,
        info    : null
    })

    /**
     * 
     * @param {object} e референция към полето, от което е извикана функцията 
     */
    const handleChange = e => {
        const { name, value }   = e.target

        setState(prev => ({
            ...prev,
            [name]              : value
        }))

        if (state.success) handleSuccess(false)
        if (state.error) handleError(false)
    }

    const handleCancelFile = () => {
        document.querySelector('#uploaded-file').reset()

        setState(prev => ({
            ...prev,
            file    : null
        }))
    }

    const handleUploadFile = e => {
        e.preventDefault()
        const data    = new FormData(e.target)
        setLoading(true)
        responseInfo(null)

        Api.post('upload', data)
            .then(res => {
                handleSuccess(true)
                responseInfo(res.data)
            })
            .catch(() => {
                handleError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const responseInfo = info => {
        setState(prev => ({
            ...prev,
            info
        }))
    }

    const handleSuccess = success => {
        setState(prev => ({
            ...prev,
            success
        }))

        if (success) {
            handleRefreshCurrentProducts()
            handleCancelFile()
        }
        if (state.error) handleError(false)
    }

    const handleError = error => {
        setState(prev => ({
            ...prev,
            error
        }))

        if (error) handleCancelFile()
        if (state.success) handleSuccess(false)
    }

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане
     */
    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    /**
     * 
     * @param {object} info данни, които да се визуализират 
     * @returns съобщение при качване на файл
     */
    const generateInfoMessage = info => {
        let message             = ''
        let color
        if (info.existing == info.total) {
            message             = 'Всички артикули от списъка са вече качени в системата.'
            color               = 'warning'
        } else if (info.existing) {
            message             = `${info.existing} от ${info.total} са вече качени в системата. ${info.new} нови артикуи бяха качени.`
            color               = 'warning'
        } else {
            message             = `${info.new} нови артикуи бяха качени.`
            color               = 'success'
        }

        return (
            <>
                <hr />
                <p
                    style={{
                        color   : `${color === 'warning' ? '#ff8d00' : color === 'success' ? '#1dcfa3' : ''}`
                    }}
                >{message}</p>
            </>
        )
    }

    return (
        <aside className="upload-file">
            <form
                className="panel"
                id="uploaded-file"
                onSubmit={handleUploadFile}
            >
                <input type="hidden" name="type" value={app.getCurrentPlatform() === 0 ? 'amazon' : 'aliexpress'} />
                <div className="head">
                    <h2>
                        1. Качване на файл (<span className="color" style={{ backgroundColor: app.getCurrentPlatformColor(app.getCurrentPlatform()) }}></span>{app.getCurrentPlatform() === 0 ? 'AMAZON' : 'AliExpress'})
                    </h2>
                </div>
                <hr />
                {state.success ?
                    <div className="success">
                        Действието беше успешно.
                        {state.info ?
                            generateInfoMessage(state.info)
                            :
                            <>
                            </>
                        }
                    </div>
                    :
                    <>
                    </>
                }
                {state.error ?
                    <div className="error">
                        Нещо се обърка!
                    </div>
                    :
                    <>
                    </>
                }
                {state.loading ?
                    <img
                        src={loader}
                        className="loading"
                        style={{
                            margin: 'auto'
                        }}
                    />
                    :
                    state.file ?
                        <>
                            <div className="no-data">
                                Файлът е готов
                                <br />
                                за изпращане.
                            </div>
                            {app.getCurrentPlatform() === 1 ?
                                <>
                                    <hr />
                                    <FormControlLabel control={<Checkbox defaultChecked />} name="with_free_return_only" value="1" label='Сваляне само на артикули с "Free return" от AliExpress' />
                                </>
                                :
                                <>
                                </>
                            }
                        </>
                        :
                        <>
                        </>
                }
                <Tooltip title="Прикачване на файл">
                    <div
                        className="upload"
                        style={state.file ? {
                            opacity: '0',
                            pointerEvents: 'none',
                            position: 'absolute'
                        } : {}}
                    >
                        <img src={uploadIcon} />
                        <h6>
                            Прикачване на файл
                        </h6>
                        <p>
                            Допустим формат: .txt
                        </p>
                        <input
                            type="file"
                            name="file"
                            title=""
                            value={state.file}
                            onChange={handleChange}
                        />
                    </div>
                </Tooltip>
                <div className="buttons">
                    <Tooltip title="Изчистване на качения файл">
                        <Button
                            variant="outlined"
                            disabled={!state.file || state.loading}
                            onClick={handleCancelFile}
                        >
                            Отказ
                        </Button>
                    </Tooltip>
                    <Tooltip title="Заявка за артикулите от съдържанието на файла">
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!state.file || state.loading}
                        >
                            Изпращане
                        </Button>
                    </Tooltip>
                </div>
            </form>
        </aside >
    )
}

export default UploadFile