import { useState } from 'react'

//mui components
import { Button, TextField, InputAdornment, Menu, MenuItem, Tooltip } from '@mui/material'

//images
import dotsIcon from '../../../../assets/images/scraper/icons/dots.png'
import updateIcon from '../../../../assets/images/scraper/icons/update.png'

//helpers
import Api from '../../../../helpers/Api'

//proivders
import { useAppContext } from '../../../../providers/App'

const CurrentProductRow = props => {
    const app = useAppContext()
    const { 
        i, 
        handleTax, 
        handleQty, 
        handleRemoveProduct,
        editedData    = {},
     }                = props

    const [state, setState] = useState({
        menu        : null,
        updated     : false,
        disabled    : false
    })

    const handleClick = e => {
        setState(prev => ({
            ...prev,
            menu: e?.currentTarget || null
        }))
    }

    const handleClose = () => {
        handleClick(null)
    }

    /**
    * 
    * @param {string} string низ
    * @param {number} length дължина на низа 
    * @returns ограничен string, ако дължината му е повече от length
    */
    const tringString = (string, length) => {
        let trimmedString = '-'

        if (!string || !length) {
            return trimmedString
        }

        if (string.length > length) {
            trimmedString = `${string.substring(0, length)}...`
        } else {
            trimmedString = i.title
        }

        return trimmedString
    }

    const handleSync = () => {
        let allow = i.allow_ebay_sync === 1 ? 0 : 1
        
        Api.post('allow-ebay-sync', {
            id: i.id,
            allow
        })
            .then(() => {
                i.allow_ebay_sync = allow
                app.showMessage('Действието беше успешно!', 'success')
            })

        handleClose()
    }

    const viewOnAmazon = () => {
        window.open(i.type === 'amazon' ? `https://www.amazon.co.uk/dp/${i.amazon_id}` : `https://www.aliexpress.com/item/${i.amazon_id}.html`, '_blank')
        
        handleClose()
    }

    const handleRemove = () => {
        handleRemoveProduct(i.id)
        handleClose()
    }

    const setUpdated = updated => {
        setState(prev => ({
            ...prev,
            updated
        }))
    }

    /**
     * 
     * @returns цената + % надценка, ако има такава
     */
     const getTotalPrice = () => {
        let totalPrice    = 0
        let percent       = Number(editedData?.tax) || Number(i.profit_perc)

        if (percent) {
            totalPrice    = percent.toFixed(2)
        } else {
            totalPrice    = Number(i.amazon_price).toFixed(2)
        }

        return (Number(totalPrice) + Number(app.epayCharge)).toFixed(2)
    }

    const handleUpdate = () => {
        Api.post('update', {
            id: i.id,
            qty: document.querySelector(`input[name="[${i.id}][qty]"]`).value,
            profit_perc: document.querySelector(`input[name="[${i.id}][tax]"]`).value,
            use_fixed_price: 0,
        })

        syncPrices()
        app.showMessage('Артикула е актуализиран успешно!', 'success')
    }

    const syncPrices = () => {
        // i.amazon_price = getTotalPrice()
        setUpdated(false)
        // document.querySelector(`input[name="[tax][${i.id}]"]`).value = ''
        // document.querySelector(`input[name="qty[${i.id}]"]`).value = ''
    }

    const handleFetch = () => {
        handleClose()
        setDisabled(true)
        const data = new FormData()
        data.append('id', i.id)

        Api.post('publish', data)
            .then(() => {
                app.showMessage('Артикула е актуализиран успешно!', 'success')
            })
            .finally(() => {
                setDisabled(false)
            })
    }

    const setDisabled = disabled => {
        setState(prev => ({
            ...prev,
            disabled
        }))
    }

    return (
        <tr className={state.disabled ? 'disabled' : ''}>
            <td>
                <Tooltip title={i.title || 'Липсва име на артикула'}>
                    <div className="holder">
                        <img src={i?.media[0]?.original_src} />
                        <span 
                            className="color" 
                            style={{ backgroundColor: app.getCurrentPlatformColor(i.type === 'amazon' ? 0 : 1) }}
                        >
                        </span>
                        {tringString(i?.title, 50)}
                    </div>
                </Tooltip>
            </td>
            <td className="text-right">
                {Number(i?.amazon_price).toFixed(2)} {i?.currency?.symbol}
            </td>
            <td>
                <TextField
                    type="number"
                    name={`[${i.id}][tax]`}
                    defaultValue={i.profit_perc || editedData[`[${i.id}][tax]`] || app.profit}
                    onChange={e => {
                        handleTax(e, i)
                        setUpdated(true)
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    variant="standard"
                />
            </td>
            <td className="text-right">
                {editedData?.tax ? getTotalPrice() : i.price.toFixed(2)} {i?.currency?.symbol}
            </td>
            <td>
                <TextField
                    type="number"
                    name={`[${i.id}][qty]`}
                    defaultValue={i?.qty < 0 ? 0 : i?.qty || 1}
                    onChange={e => {
                        handleQty(e, i)
                        setUpdated(true)
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">бр.</InputAdornment>,
                    }}
                    variant="standard"
                />
            </td>
            <td className="options text-center">
                <div
                    className="row"
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Tooltip title="Актуализация на избраният артикул">
                        <Button
                            onClick={handleUpdate}
                            style={{
                                minWidth: 'initial'
                            }}
                            disabled={!state.updated}
                            className={!state.updated ? 'disabled' : ''}
                        >
                            <img
                                src={updateIcon}
                                style={{
                                    height: '20px'
                                }}
                            />
                        </Button>
                    </Tooltip>
                    <img
                        src={dotsIcon}
                        onClick={handleClick}
                        className="options-menu-button"
                    />
                    <Menu
                        id="basic-menu"
                        anchorEl={state.menu}
                        open={Boolean(state.menu)}
                        onClose={handleClose}
                    >
                         <MenuItem
                            onClick={handleFetch}
                        >
                           Принудителна актуализация
                        </MenuItem>
                        <MenuItem
                            onClick={handleSync}
                        >
                            {i.allow_ebay_sync === 1 ?
                                'Спиране на актуализацията'
                                :
                                'Разреши актуализация'
                            }

                        </MenuItem>
                        <MenuItem
                            onClick={viewOnAmazon}
                        >
                            Преглед в {i.type === 'amazon' ? 'Amazon' : 'AliExpress'}
                        </MenuItem>
                        {i.published ?
                            <MenuItem
                                onClick={handleRemove}
                            >
                                Премахване от eBay
                            </MenuItem>
                            :
                            <>
                            </>
                        }
                    </Menu>
                </div>
            </td>
        </tr>
    )
}

export default CurrentProductRow