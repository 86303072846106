import { useState, useEffect } from 'react'

//mui components
import { Tooltip, TextField, Button } from '@mui/material'

//images
import userIcon from '../../../assets/images/login/icons/user.png'

//providers
import { useAuthDataContext } from '../../../providers/Auth'

//helpers
import Api from '../../../helpers/Api'

const Login = () => {
    const auth = useAuthDataContext()

    const [state, setState] = useState({
        loading: false,
        grow: false,
        error: false
    })

    /**
     * 
     * @param {object} e референция към формата 
     */
    const handleLogin = e => {
        e.preventDefault()
        setLoading(true)
        handleError(false)
        const data = new FormData(e.target)

        Api.post('auth/login', data)
            .then(res => {
                handleGrow()
                setTimeout(() => {
                    auth.handleLogin(res.data)
                }, 1500)
            })
            .catch(() => {
                handleError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане
     */
    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleError = error => {
        setState(prev => ({
            ...prev,
            error
        }))
    }

    const handleGrow = () => {
        setState(prev => ({
            ...prev,
            grow: true
        }))
    }

    useEffect(() => {
        let counter = 0

        setInterval(() => {
            const motoEls = document.querySelectorAll('.moto li')
            const activeMotoEl = document.querySelector('.moto li.active')
            if (motoEls && activeMotoEl) {
                activeMotoEl.classList.remove('active')
                motoEls[counter].classList.add('active')

                if (counter >= 2) {
                    counter = 0
                } else {
                    counter++
                }
            }
        }, 5000)
    }, [])

    return (
        <main id="login">
            <div className="wrapper">
                <form
                    onSubmit={handleLogin}
                    autoComplete="new-password"
                    className={state.grow ? 'grow' : ''}
                >
                    <img src={userIcon} />
                    <h1>
                        Добре дошли!
                    </h1>
                    <div className="row">
                        <div className="col">
                            <TextField
                                className="test"
                                label="Е-поща*"
                                type="text"
                                name="username"
                                variant="standard"
                                error={state.error}
                                autoComplete="new-password"
                                autoFocus={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TextField
                                label="Парола*"
                                type="password"
                                name="password"
                                variant="standard"
                                error={state.error}
                                autoComplete="new-password"
                                className="animated"
                            />
                        </div>
                    </div>
                    <div className="buttons row">
                        <Tooltip title="Вход в системата">
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={state.loading}
                            >
                                Вход
                            </Button>
                        </Tooltip>
                    </div>
                </form>
                <ul className="moto">
                    <li className="active">
                        Автоматизация.
                    </li>
                    <li>
                        Самообучение.
                    </li>
                    <li>
                        Успешен бизнес.
                    </li>
                </ul>
            </div>
        </main>
    )
}


export default Login