import { useState, useEffect, useImperativeHandle, forwardRef } from 'react'

//mui components
import { Tooltip, TextField, InputAdornment, Button, MenuItem, Select, InputLabel } from '@mui/material'

//images
import uploadIcon from '../../assets/images/scraper/icons/upload.png'
import removeIcon from '../../assets/images/scraper/icons/remove.png'
import loader from '../../assets/images/scraper/loading.svg'

//helpers
import Api from '../../helpers/Api'

//providers
import { useAppContext } from "../../providers/App"
import { useAuthDataContext } from '../../providers/Auth'

let timeout
function Question(props, ref) {
    const app   = useAppContext()
    const auth  = useAuthDataContext()

    const [state, setState] = useState({
        overlay               : false,
        modal                 : false,
        file                  : null,
        epayCharge            : 0,
        defaultEbayQuantity   : 1,
        profitPercent         : 0,
        loading               : true,
        loadingSave           : false,
        moreLoading           : false,
        bannerdWords: {
            items             : [],
            page              : 0,
            pages             : 1
        },
        inventories           : {},
        policies              : {},
        edited                : false,
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            handleOpen()
        },
        close: () => {
            handleClose()
        },
    }))

    const handleOpen = () => {
        setState(prev => ({
            ...prev,
            overlay   : true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal : true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            modal         : false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay   : false,
            }))
        }, 100)
    }

    /**
    * 
    * @param {object} e референция към формата 
    */
    const handleSave = async (e) => {
        e.preventDefault()
        setLoadingSave(true)

        try {
            await saveEpayCharge()
            await saveEbayQty()
            await saveInventory()
            await saveProfit()

            if (state.fulfillmentPolicyId || state.paymentPolicyId || state.returnPolicyId) await savePolicies()
            if (state.file) await saveBannedWords(e)
            setLoadingSave(false)
            app.handleRefreshCurrentProducts()

            if (auth.isConfigured()) {
                setState(prev => ({
                    ...prev,
                    edited    : false,
                }))
                handleClose()
                app.showMessage('Настройките бяха запазени успешно!')
            } else auth.handleConfigure()
        } catch (e) {
            setLoadingSave(false)
            app.showMessage('Нещо се обърка. Моля, проверете всички данни и опитайте отново.', 'error')
        }
    }

    const saveEpayCharge = async () => {
        const data    = new FormData()
        if (state.epayCharge) data.append('value', state.epayCharge)
        else return

        await Api.post('settings/set-epay-charge', data)
        app.setEpayCharge(state.epayCharge)
    }

    const saveEbayQty = async () => {
        const data    = new FormData()

        if (state.defaultEbayQuantity) data.append('value', state.defaultEbayQuantity)
        else return

        await Api.post('settings/set-ebay-qty', data)
    }

    const saveInventory = async () => {
        const data    = new FormData()

        if (state.merchantLocationKey) data.append('merchantLocationKey', state.merchantLocationKey)
        else return

        await Api.post('settings/set-ebay-inventory-location', data)
    }

    const savePolicies = async () => {
        const data    = new FormData()

        if (state.fulfillmentPolicyId) data.append('fulfillmentPolicyId', state.fulfillmentPolicyId || state.policies?.fulfillmentPolicies?.default)
        if (state.paymentPolicyId) data.append('paymentPolicyId', state.paymentPolicyId || state.policies?.paymentPolicies?.default)
        if (state.returnPolicyId)  data.append('returnPolicyId', state.returnPolicyId || state.policies?.returnPolicies?.default)

        await Api.post('settings/set-ebay-policies', data)
    }

    const saveProfit = async () => {
        const data    = new FormData()

        if (state.profitPercent) data.append('value', state.profitPercent)
        else return

        await Api.post('settings/set-profit', data)
        app.setProfit(state.profitPercent)
    }

    /**
     * 
     * @param {object} e референция към полето за прикачване на файл
     */
    const saveBannedWords = async (e) => {
        const data    = new FormData(e.target)
        await Api.post('banned-terms/upload', data)
    }

    /**
     * 
     * @param {object} e референция към полето, от което е иззвикана функцията 
     */
    const handleChange = e => {
        const { name, value }   = e.target

        setState(prev => ({
            ...prev,
            [name]              : value,
            edited              : true
        }))
    }

    const handleCancelFile = () => {
        document.querySelector('#settings').reset()

        setState(prev => ({
            ...prev,
            file    : null
        }))
    }

    useEffect(() => {
        getSettings()
    }, [])

    useEffect(() => {
        clearTimeout(timeout)

        timeout = setTimeout(() => {
            getBannnerWords(1, true, true)
        }, 300)
    }, [state.banned_word])

    const getSettings = async () => {
        setLoading(true)

        try {
            await Api.get('settings')
                .then(res => {
                    setState(prev => ({
                        ...prev,
                        ...res.data
                    }))
                    app.setEpayCharge(res.data?.epayCharge || 0)
                    app.setProfit(res.data?.profitPercent || 0)
                })

            await Api.get('ebay-policies')
                .then(res => {
                    setState(prev => ({
                        ...prev,
                        policies    : res.data
                    }))
                })

            await Api.get('ebay-inventory-locations')
                .then(res => {
                    setState(prev => ({
                        ...prev,
                        inventories : res.data
                    }))
                })
        } catch {
            auth.handleLogout()
        }

        setLoading(false)
    }

    /**
     * 
     * @param {number} page страницата, от която ще се върнат резултати
     */
    const getBannnerWords = (page, fromLoadMore, fromSearch) => {
        if (fromLoadMore) setMoreLoading(true)

        Api.get(`banned-terms/list?name=${state.banned_word || ''}&page=${page}`)
            .then(res => {
                setBannnerWords(res.data, fromSearch)
            })
            .finally(() => {
                if (fromLoadMore) setMoreLoading(false)
            })
    }

    /**
     * 
     * @param {array} bannerdWords списъка със забранени думи
     */
    const setBannnerWords = (bannerdWords, fromSearch) => {
        const { page, pages, items }  = bannerdWords
        setState(prev => ({
            ...prev,
            bannerdWords: {
                items                 : fromSearch ? items : [...state.bannerdWords.items, ...items],
                pages,
                page
            }
        }))
    }

    /**
     * 
     * @param {number} id id на думата
     */
    const handleRemoveBannedWord = id => {
        const data    = new FormData()
        data.append('id', id)
        Api.post('banned-terms/delete', data)

        setState(prev => ({
            ...prev,
            bannerdWords: {
                ...prev.bannerdWords,
                items : filterWordsAfterDelete(id)
            }
        }))
    }

    /**
     * 
     * @param {number} id id на думата 
     * @returns обновен списък с думи
     */
    const filterWordsAfterDelete = id => {
        const filteredWords   = state.bannerdWords.items.filter(i => i.id != id)

        return filteredWords
    }

    /**
     * 
     * @param {boolean} loadingSave дали върху бутон за запазване да се визуализира зареждане
     */
    const setLoadingSave = loadingSave => {
        setState(prev => ({
            ...prev,
            loadingSave
        }))
    }

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане
     */
    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане
     */
    const setMoreLoading = moreLoading => {
        setState(prev => ({
            ...prev,
            moreLoading
        }))
    }

    /**
     * 
     * @returns дали да се показва "Още резултати"
     */
    const showMore = () => {
        let more = true

        if ((state.bannerdWords.page === state.bannerdWords.pages) || (state.bannerdWords.pages <= 1)) {
            more = false
        }

        return more
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form onSubmit={handleSave} className={`box ${state.modal ? 'show' : ''}`}>
                <h3 className="heading">
                    Вашите настройки
                </h3>
                {state.loading ?
                    <img src={loader} className="loading" />
                    :
                    <>
                        <div className="row">
                            <div className="col small">
                                <TextField
                                    label="ePay оскъп.*"
                                    type="number"
                                    name="epayCharge"
                                    value={state.epayCharge}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    }}
                                    variant="standard"
                                    disabled={state.loadingSave}
                                />
                            </div>
                            <div className="col small">
                                <TextField
                                    label="Надценка"
                                    type="number"
                                    name="profitPercent"
                                    value={state.profitPercent}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    variant="standard"
                                    disabled={state.loadingSave}
                                />
                            </div>
                            <div className="col small">
                                <TextField
                                    label="Базово кол.*"
                                    type="number"
                                    name="defaultEbayQuantity"
                                    value={state.defaultEbayQuantity}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">бр.</InputAdornment>,
                                    }}
                                    variant="standard"
                                    disabled={state.loadingSave}
                                />
                            </div>
                            <div className="col">
                                <InputLabel id="demo-simple-select-label" className="select-label">Склад*</InputLabel>
                                <Select
                                    variant="standard"
                                    labelId="demo-simple-select-label"
                                    defaultValue={state.inventories.default}
                                    name="merchantLocationKey"
                                    onChange={handleChange}
                                >
                                    {(state.inventories?.data?.locations || []).map(l =>
                                        <MenuItem value={l.merchantLocationKey}>
                                            {l.location.address.country}, {l.location.address.city}, {l.location.address.addressLine1 || '-'}
                                        </MenuItem>
                                    )}
                                </Select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <InputLabel id="demo-simple-select-label-2" className="select-label">Fulfillment policies*</InputLabel>
                                <Select
                                    variant="standard"
                                    labelId="demo-simple-select-label-2"
                                    defaultValue={state.policies?.fulfillmentPolicies?.default}
                                    name="fulfillmentPolicyId"
                                    onChange={handleChange}
                                >
                                    {(state.policies?.fulfillmentPolicies?.data?.fulfillmentPolicies || []).map(l =>
                                        <MenuItem value={l.fulfillmentPolicyId}>
                                            {l.name}
                                        </MenuItem>
                                    )}
                                </Select>
                            </div>
                            <div className="col">
                                <InputLabel id="demo-simple-select-label-3" className="select-label">Payment policies*</InputLabel>
                                <Select
                                    variant="standard"
                                    labelId="demo-simple-select-label-3"
                                    name="paymentPolicyId"
                                    defaultValue={state.policies?.paymentPolicies?.default}
                                    onChange={handleChange}
                                >
                                    {(state.policies?.paymentPolicies?.data?.paymentPolicies || []).map(l =>
                                        <MenuItem value={l.paymentPolicyId}>
                                            {l.name}
                                        </MenuItem>
                                    )}
                                </Select>
                            </div>
                            <div className="col">
                                <InputLabel id="demo-simple-select-label-4" className="select-label">Return policies*</InputLabel>
                                <Select
                                    variant="standard"
                                    labelId="demo-simple-select-label-4"
                                    name="returnPolicyId"
                                    defaultValue={state.policies?.returnPolicies?.default}
                                    onChange={handleChange}
                                >
                                    {(state.policies?.returnPolicies?.data?.returnPolicies || []).map(l =>
                                        <MenuItem value={l.returnPolicyId}>
                                            {l.name}
                                        </MenuItem>
                                    )}
                                </Select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col" style={{ height: '150px' }}>
                                <TextField
                                    label="Търсене на думи..."
                                    type="text"
                                    name="banned_word"
                                    value={state.banned_word}
                                    onChange={handleChange}
                                    variant="standard"
                                    disabled={state.loadingSave}
                                    style={{
                                        marginBottom: '10px'
                                    }}
                                />
                                {(state.bannerdWords?.items || []).length ?
                                    <ul className="banned-words" style={{ height: '150px' }}>
                                        {(state.bannerdWords?.items || []).map(b =>
                                            <li key={b.id}>
                                                {b.name}
                                                <Tooltip title={`Премахване на ${b.name}`}>
                                                    <img
                                                        src={removeIcon}
                                                        onClick={() => {
                                                            handleRemoveBannedWord(b.id)
                                                        }}
                                                    />
                                                </Tooltip>
                                            </li>
                                        )}
                                        {showMore() ?
                                            state.moreLoading ?
                                                <img src={loader} className="loading" />
                                                :
                                                <li
                                                    className="link"
                                                    onClick={() => {
                                                        getBannnerWords(Number(state.bannerdWords.page) + 1, true)
                                                    }}
                                                >
                                                    Още резултати
                                                </li>
                                            :
                                            <>
                                            </>
                                        }
                                    </ul>
                                    :
                                    <>
                                         {state.banned_word.length ? 
                                            <p className="no-data">
                                                Няма резултати
                                            </p> 
                                            :
                                            ''   
                                        }
                                    </>
                                    }
                                </div>
                        </div>
                        <div
                            className={`row ${state.loadingSave}`}
                        >
                            <div className="col">
                                {state.file ?
                                    <div className="no-data">
                                        Файлът е готов за запазване.
                                        <br />
                                        <Tooltip title="Премахване на качения файл и прикачване на нов.">
                                            <span
                                                className="link"
                                                onClick={handleCancelFile}
                                            >
                                                Качи нов.
                                            </span>
                                        </Tooltip>
                                    </div>
                                    :
                                    <>
                                    </>
                                }
                                <Tooltip title="Прикачване на файл">
                                    <div
                                        className="upload"
                                        style={state.file ? {
                                            opacity: '0',
                                            pointerEvents: 'none',
                                            position: 'absolute'
                                        } : {}}
                                    >
                                        <img src={uploadIcon} />
                                        <h6>
                                            Прикачване на файл със забранени фрази
                                        </h6>
                                        <p>
                                            Допустим формат: .txt
                                        </p>
                                        <input
                                            type="file"
                                            name="file"
                                            title=""
                                            value={state.file}
                                            onChange={handleChange}
                                            accept=".txt"
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </>
                }
                <div className="buttons">
                    {auth.isConfigured() ?
                        <Tooltip title="Скриване на настройките">
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                                disabled={state.loadingSave}
                            >
                                Назад
                            </Button>
                        </Tooltip>
                        :
                        <>
                        </>
                    }
                    <Tooltip title="Запазване на промените">
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={state.loadingSave || !state.edited}
                        >
                            {auth.isConfigured() ?
                                'Запазване'
                                :
                                'Към системата'
                            }
                        </Button>
                    </Tooltip>
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Question)