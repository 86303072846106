//providers
import { Tabs, Tab } from '@mui/material'
import { useAppContext } from '../../../../providers/App'

const PlatformTabs = () => {
    const app = useAppContext()

    const handleChange = (e, typeId) => {
        app.setCurrentPlatform(typeId)
    }

    return (
        <Tabs
            value               = {app.getCurrentPlatform()}
            onChange            = {handleChange}
            style={{
                marginBottom    : '15px',
                width           : '100%'
            }}
        >
            {Object.keys(app.getTabs()).map((t, i) =>
                <Tab
                    label={
                        <>
                            <span
                                style={{
                                    backgroundColor   : app.getCurrentPlatformColor(i)
                                }}
                                className="color"
                            >

                            </span>
                            {Object.values(app.getTabs())[i]}
                        </>
                    }
                    key                               = {t}
                    value                             = {Number(Object.keys(app.getTabs())[i])}
                />
            )}
        </Tabs>
    )
}

export default PlatformTabs