import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

//mui components
import { Tooltip, Button } from '@mui/material'

//images
import ebayLogoIcon from '../../../assets/images/login/icons/ebay.png'

//providers
import { useAuthDataContext } from '../../../providers/Auth'

//helpers
import Api from '../../../helpers/Api'

const Verify = () => {
    const auth        = useAuthDataContext()
    const location    = useLocation()

    const [state, setState] = useState({
        loading           : false,
        fadeOut           : false,
        checkingStatus    : false
    })

    useEffect(async () => {
        if (location.search){
            Api.get(`ebay-auth${location.search}`)
                .then(async () => {
                    const status = await auth.getEbayStats()

                    if (status) {
                        handleFadeOut()
                        setTimeout(() => {
                            auth.handleVerify()
                        }, 1000)
                        setLoading(false)
                    } else auth.handleLogout()
                })
                .catch(() => {
                    auth.handleLogout()
                })
        } else{
            setChackingStatus(true)
            const status = await auth.getEbayStats()

            if(status){
                handleFadeOut()
                setTimeout(() => {
                    auth.handleVerify()
                    setChackingStatus(false)
                }, 1000)
            } else{
                setChackingStatus(false)
            }
        }
    }, [])

    const setChackingStatus = checkingStatus => {
        setState(prev => ({
            ...prev,
            checkingStatus
        }))
    }

    const handleVerify = e => {
        e.preventDefault()
        setLoading(true)

        Api.get('ebay-auth-url')
            .then(res => {
                window.location.href = res.data.url
            })
            .catch(() => {
                auth.handleLogout()
            })
    }

    const handleFadeOut = () => {
        setState(prev => ({
            ...prev,
            fadeOut: true
        }))
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    return (
        <main id="verify">
            <div className={`wrapper ${state.fadeOut ? 'fade-out' : ''}`}>
                <img src={ebayLogoIcon} />
                <h1>
                    Верификация на eBay профил
                </h1>
                <Tooltip title="Кликвайки на бутона ще потвърдите Вашият профил">
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={state.loading || location.search || state.checkingStatus}
                        onClick={handleVerify}
                    >
                        {state.checkingStatus ? 'Проверка на данни...' : location.search ? 'Верифициране на профила...' : state.loading ? 'Пренасочване към eBay...' : 'Вход чрез eBay'}
                    </Button>
                </Tooltip>
            </div>
        </main>
    )
}


export default Verify