import { useEffect, useRef, useState } from 'react'

//mui components
import { Tooltip, Button } from '@mui/material'

//images
import settingsIcon from '../../../assets/images/login/icons/settings.png'

//modals
import SettingsModal from '../../../components/modals/Settings'

//helpers
import Api from '../../../helpers/Api'

//providers
import { useAuthDataContext } from '../../../providers/Auth'
import { useAppContext } from '../../../providers/App'

const Settings = () => {
    const app = useAppContext()
    const auth = useAuthDataContext()

    const [state, setState] = useState({
        checking    : true,
        isOk        : true
    })

    const settingsModalRef = useRef(null)

    useEffect(() => {
        getSettings()
    }, [])

    const getSettings = () => {
        Api.get('settings/ebay-setup-status')
            .then(res => {
                let isOk        = state.isOk
                Object.values(res.data).map(v => {
                    if (!v) {
                        isOk    = false
                    }
                })

                setState(prev => ({
                    ...prev,
                    checking    : false,
                    isOk
                }))

                if (isOk) auth.handleConfigure()
            })
            .catch(() => {
                auth.handleLogout()
            })
    }

    return (
        <section id="settings">
            <SettingsModal
                ref={settingsModalRef}
            />
            <div className="wrapper">
                <img src={settingsIcon} />
                <h1>
                    Потребителски настройки
                </h1>
                <p>
                    За да продължите към системата, трябва първо да настроите Вашият профил.
                </p>
                <Tooltip title="Кликвайки на бутона ще започнете настройката на профилът Ви">
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={() => {
                            settingsModalRef.current.open()
                        }}
                        disabled={state.checking}
                    >
                        {state.checking ? 'Проверка на настройките...' : 'Към настройки'}
                    </Button>
                </Tooltip>
            </div>
        </section>
    )
}

export default Settings