//providers
import { useAppContext } from "../../providers/App"

const Message = () => {
    const app = useAppContext()
    const { message } = app
    
    return (
        message.show ?
            <div className={`message ${message.className}`}>
                {message.text}
            </div>
            :
            <>
            </>
    )
}

export default Message