import { useState } from 'react'
import { useLocation, Navigate } from 'react-router-dom'

//partials
import Header from "./partials/Header"
import UploadFile from './partials/UploadFile'
import CurrentProducts from "./partials/CurrentProducts"
import PublishedProducts from "./partials/PublishedProducts"
import PlatformTabs from "./partials/Tabs"

const Index = () => {
    const location = useLocation()

    const [state, setState] = useState({
        refreshCurrentProduct       : null,
        refreshPublishedProducts    : null
    })

    if (location.search){
        return <Navigate to   = "/" />
    }

    const handleRefreshCurrentProducts = () => {
        setState(prev => ({
            ...prev,
            refreshCurrentProduct   : new Date()
        }))
    }

    const handleRefreshPublishedProducts = () => {
        setState(prev => ({
            ...prev,
            refreshPublishedProducts    : new Date()
        }))
    }

    return (
        <>
            <Header />
            <div className="wrapper content">
                <PlatformTabs />
                <UploadFile
                    handleRefreshCurrentProducts={handleRefreshCurrentProducts}
                />
                <CurrentProducts
                    key={state.refreshCurrentProduct}
                    handleRefreshPublishedProducts={handleRefreshPublishedProducts}
                />
                <PublishedProducts 
                    key={state.refreshPublishedProducts}
                />
            </div>
        </>
    )
}


export default Index