import { createContext, useState, useContext } from "react"

export const AppContext = createContext({})

const PLATFORMS = {
    0 : 'amazon',
    1 : 'aliexpress',
}
const PLATFORM_COLORS = {
    0: '#507bfb',
    1: '#50fbac'
}
const initialMessage = {
    show        : false,
    text        : '',
    className   : '',
}
let timeout
const AppProvider = props => {
    const [state, setState] = useState({
        aditionalSettings         : false,
        message                   : initialMessage,
        profit                    : 0,
        epayCharge                : 0,
        refreshCurrentProducts    : null,
        currentPlatform           : 0
    })

    /**
     * връща всички платформи
     * @returns {object}
     */
    const getTabs = () => PLATFORMS

    /**
     * 
     * @param {number} platform ид на платофрмата (писани са в PLATFORMS обекта) 
     */
    const setCurrentPlatform = currentPlatform => {
        setState(prev => ({ 
            ...prev,
            currentPlatform
        }))
    }

    /**
     * връща type id на текущата платформ.
     * @returns {number}
     */
    const getCurrentPlatform = () => Number(state.currentPlatform)

    const handleRefreshCurrentProducts = () => {
        setState(prev => ({
            ...prev,
            refreshCurrentProducts: new Date()
        }))
    }

    /**
     * връща цвета на текущата платформа
     * @param {number} index 
     * @returns {string}
     */
    const getCurrentPlatformColor = index => PLATFORM_COLORS[index]

    /**
     * 
     * @param {number} profit обща надценка
     */
    const setProfit = profit => {
        setState(prev => ({
            ...prev,
            profit
        }))
    }

    const setEpayCharge = epayCharge => {
        setState(prev => ({
            ...prev,
            epayCharge
        }))
    }

    /**
     * 
     * @param {boolean} aditionalSettings дали да се покажат настройките
     */
    const viewSettings = aditionalSettings => {
        setState(prev => ({
            ...prev,
            aditionalSettings
        }))
    }

    /**
     * 
     * @param {string} text съобщение, което да се визуализира 
     * @param {string} className css клас, който да бъде добавен на елемента 
     */
    const showMessage = (text, className = '') => {
        setState(prev => ({
            ...prev,
            message: {
                show    : Boolean(text),
                text,
                className
            }
        }))

        clearTimeout(timeout)
        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                message : initialMessage
            }))
        }, 5000)
    }

    const exportedData = {
        ...state,
        viewSettings,
        setProfit,
        setEpayCharge,
        handleRefreshCurrentProducts,
        showMessage,
        getCurrentPlatform,
        setCurrentPlatform,
        getTabs,
        getCurrentPlatformColor
    }

    return <AppContext.Provider value={exportedData} {...props} />
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider