import React, { createContext, useState, useContext, useEffect } from "react"

//helpers
import Api from "../helpers/Api"

export const AuthDataContext = createContext({})

const initialAuthData = {
    access_token: undefined,
    user: {},
}

const AuthDataProvider = props => {
    const [authData, setAuthData] = useState(initialAuthData)

    const getAuthData = async () => {
        let currentAuthData = initialAuthData

        try {
            if (localStorage.getItem('auth')) {
                currentAuthData = JSON.parse(localStorage.getItem('auth'))
            }
        } catch (e) {
            console.error(e)
        }

        setAuthData(currentAuthData)
    }

    /**
     * 
     * @returns eBay статус
     */
    const getEbayStats = async () => {
        let ok = false
        await Api.get('ebay-status')
            .then(res => {
                if (res.data.status) {
                    ok = true
                }
            })

        return ok
    }

    useEffect(() => {
        getAuthData()
    }, [])

    /**
     * 
     * @returns дали потребителя е логнат true/false
     */
    const isLogged = () => {
        let logged = false

        if (authData.access_token) {
            logged = true
        }

        return logged
    }

    /**
     * 
     * @returns дали потребителя е верифициран чрез еBay профила си
     */
    const isVerified = () => {
        let verified = false

        if (authData.isVerified) {
            verified = true
        }

        return verified
    }

    /**
     * 
     * @returns дали потребителя е настроил профила си
     */
    const isConfigured = () => {
        let configured = false

        if (authData.isConfigured) {
            configured = true
        }

        return configured
    }

    /**
     * 
     * @returns данни за потребителя
     */
    const getUser = () => {
        return authData.user
    }

    const loadData = () => {

    }

    const handleRefresh = () => {
        loadData()
    }

    const handleLogin = newAuthData => {
        localStorage.setItem('auth', JSON.stringify(newAuthData))

        setAuthData(newAuthData)
    }

    const handleVerify = () => {
        const newAuthData = {
            ...authData,
            isVerified: true,
        }

        localStorage.setItem('auth', JSON.stringify(newAuthData))

        setAuthData(newAuthData)
    }

    const handleConfigure = () => {
        const newAuthData = {
            ...authData,
            isConfigured: true,
        }

        localStorage.setItem('auth', JSON.stringify(newAuthData))

        setAuthData(newAuthData)
    }

    const handleLogout = () => {
        setAuthData(initialAuthData)
    }

    /** @return потребителския токен */
    const getToken = () => {
        let token

        if (authData.data) {
            token = authData.user.access_token
        } else {
            token = null
        }

        return token
    }

    const auth = {
        isLogged,
        isVerified,
        isConfigured,
        getUser,
        getToken,
        loadData,
        getEbayStats,
        handleLogin,
        handleRefresh,
        handleVerify,
        handleConfigure,
        handleLogout
    }

    return <AuthDataContext.Provider value={auth} {...props} />
}

export const useAuthDataContext = () => useContext(AuthDataContext)

export default AuthDataProvider