import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from "react-router-dom"

//stack
import App from './App'

//providers
import AppProvider from './providers/App'
import AuthProvider from './providers/Auth'

const history = require("history").createLocation

ReactDOM.render(
  <Router history={history}>
    <AppProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </AppProvider>
  </Router>,
  document.getElementById('root')
)

reportWebVitals()
