import { useEffect, useState } from "react"

//mui components
import { Tooltip, TextField, InputAdornment, Button, ClickAwayListener } from '@mui/material'

//partials
import CurrentProductRow from "./CurrentProductRow"
import Pagination from '../../../partials/Pagination'

//helpers
import Api from '../../../../helpers/Api'

//images
import loader from '../../../../assets/images/scraper/loading.svg'

//providers
import { useAppContext } from "../../../../providers/App"

let timeout
const CurrentProducts = props => {
    const app = useAppContext()
    const { handleRefreshPublishedProducts } = props

    const [state, setState] = useState({
        filter: {
            from        : '',
            to          : '',
            name        : '',
            page        : 1,
        },
        data: {
            items       : [],
            pages       : 1
        },
        editedData      : {},
        success         : false,
        error           : false,
        loading         : true,
        viewFilter      : false,
        showGroupTax    : false,
        confirmDelete   : false
    })

    /**
     * 
     * @param {object} e рефренция към полето, от което се вика
     */
    const handleChange = (e, id) => {
        const { name, value }   = e.target

        setState(prev => ({
            ...prev,
            editedData: {
                ...prev.editedData,
                [name]    : value
                // [id]: {
                //     ...prev.editedData[id],
                //     [name]    : value
                // }
            }
        }))
    }

    //При смяна на филтъра да се извика handlePage(1), за да може заявката да връща резуллтати от 1ва страница.
    const handleChangeFilter = e => {
        const { name, value }   = e.target

            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [name]      : value
                }
            }))
    }

    const handleTax = (e, data) => {
        const { value }   = e.target
        const { id }      = data

        handleChange(e, id)
        calcAndApplyTax(value, data)
    }

    const calcAndApplyTax = (tax, data) => {
        const { id, amazon_price }  = data
        const updatedTax            = Number(amazon_price) + (Number(amazon_price) * (0.01 * Number(tax)))

        setState(prev => ({
            ...prev,
            editedData: {
                ...prev.editedData,
                [id]: {
                    ...prev.editedData[id],
                    tax             : updatedTax,
                }
            }
        }))
    }

    /**
     * 
     * @param {boolean} success дали да се визуализира успех
     */
    const handleSuccess = success => {
        setState(prev => ({
            ...prev,
            success
        }))

        if (state.error) handleError(false)
    }

    /**
     * 
     * @param {boolean} error дали да се визуализира грешка
     */
    const handleError = error => {
        setState(prev => ({
            ...prev,
            error
        }))

        if (state.success) handleSuccess(false)
    }

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане
     */
    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))

        if (state.success) handleSuccess(false)
        if (state.error) handleError(false)
    }

    const viewFilter = () => {
        setState(prev => ({
            ...prev,
            viewFilter    : !prev.viewFilter
        }))
    }

    useEffect(() => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            getProducts()
        }, 300)
    }, [state.filter, app.refreshCurrentProducts])

    const getProducts = () => {
        setLoading(true)
        // batch=1&
        Api.get(`list?status=not-published&visible=1&name=${state.filter.name}&price_from=${state.filter.from}&price_to=${state.filter.to}&sort=id-asc&page=${state.filter.page}`)
            .then(res => {
                setProducts(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    /**
     * 
     * @param {object} data данн за продуктите 
     */
    const setProducts = data => {
        setState(prev => ({
            ...prev,
            data
        }))
    }

    /**
     * 
     * @param {number} page страница 
     */
    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page    : page.selected + 1
            }
        }))
    }

    /**
     * 
     * @param {string} id id на продукт 
     */
    const handleRemoveProduct = id => {
        Api.post('delete', {
            id
        })
            .then(() => {
                getProducts()
            })
    }

    const handlePublish = () => {
        const data = new FormData()
        Object.keys(state.editedData).map((k, i) => {
            data.append(`data${k}`, Object.values(state.editedData)[i])
        })
        
        Api.post('set-as-ready', data)
            .then(() => {
                getProducts()
                handleRefreshPublishedProducts()
            })
    }

    //todo заявка + groupTax 
    const handleAddGroupTax = () => {
        setShowGroupTax(false)
        const groupTax = state.editedData.group_tax

        const data = new FormData()
        if(Number(groupTax)) data.append('tax', groupTax)
        else return

        setLoading(true)
        Api.post(`mass-update-profit-percent?status=not-published&visible=1&name=${state.filter.name}&price_from=${state.filter.from}&price_to=${state.filter.to}&sort=id-asc&page=${state.filter.page}`, data)
            .then(() => {
                getProducts()
                app.showMessage('Действието беше успешно!', 'success')
            })
    }

    const setShowGroupTax = showGroupTax => {
        setState(prev => ({
            ...prev,
            showGroupTax
        }))
    }

    
    const setConfirmDelete = confirmDelete => {
        setState(prev => ({
            ...prev,
            confirmDelete
        }))
    }
    const handleMassDelete = () => {
        if(!state.confirmDelete){
            setConfirmDelete(true)
            return
        } 

        setLoading(true)
        Api.post(`mass-delete?status=not-published&visible=1&name=${state.filter.name}&price_from=${state.filter.from}&price_to=${state.filter.to}&sort=id-asc&page=${state.filter.page}`)
            .then(res => {
                getProducts()
                app.showMessage(`${res.data.deleted} aртикули бяха премахнати успешно!`, 'success')
            })
            .finally(() => {
                setConfirmDelete(false)
            })
    }

    return (
        <main className="panel">
            <div className="head">
                <h2>
                    2. Артикули готови за публикуване (<span className="color" style={{ backgroundColor: app.getCurrentPlatformColor(0) }}></span>AMAZON и <span className="color" style={{ marginLeft: '5px', backgroundColor: app.getCurrentPlatformColor(1) }}></span>AliExpress)
                </h2>
                <Tooltip title="Управление на филтъра">
                    <span
                        onClick={viewFilter}
                        className="link"
                    >
                        {state.viewFilter ?
                            'Скриване на филтър'
                            :
                            'Показване на филтър'
                        }
                    </span>
                </Tooltip>
            </div>
            <hr />
            {state.viewFilter ?
                <div className="filter">
                    <TextField
                        label="Име на артикул"
                        type="text"
                        name="name"
                        value={state.filter.Numbername}
                        onChange={handleChangeFilter}
                        variant="standard"
                        className="name"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"> </InputAdornment>,
                        }}
                        placeholder="Търсене..."
                    />
                    <TextField
                        label="Цена от"
                        type="number"
                        name="from"
                        value={state.filter.from}
                        onChange={handleChangeFilter}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        variant="standard"
                    />
                    <TextField
                        label="Цена до"
                        type="number"
                        name="to"
                        value={state.filter.to}
                        onChange={handleChangeFilter}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        variant="standard"
                    />
                </div>
                :
                <>
                </>
            }

            {state.success ?
                <div className="success">
                    Артикулите бяха успешно качени.
                </div>
                :
                <>
                </>
            }
            {state.error ?
                <div className="errpr">
                    Нещо се обърка!
                </div>
                :
                <>
                </>
            }
            {state.loading ?
                <img src={loader} className="loading" />
                :
                state.data?.items && state.data?.items[0] ?
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Артиикул
                                </th>
                                <th className="text-right">
                                    Цена
                                </th>
                                <th>
                                    % Оскъп.
                                </th>
                                <th className="text-right">
                                    Кр. цена
                                </th>
                                <th className="options text-center">
                                    Опции
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.data.items.map(i =>
                                <CurrentProductRow
                                    key={i.id}
                                    handleTax={handleTax}
                                    handleRemoveProduct={handleRemoveProduct}
                                    editedData={state.editedData[i.id]}
                                    i={i}
                                    value={state[`[tax][${i.id}]`]}
                                    handleRefreshPublishedProducts={handleRefreshPublishedProducts}
                                />
                            )}
                        </tbody>
                    </table>
                    :
                    <p className="no-data">
                        Не бяха намерени артикули.
                    </p>
            }
            <div className="buttons">
                <Pagination
                    page={state.filter.page}
                    pages={state.data.pages}
                    handlePage={handlePage}
                />
                <div className="row align-center">
                    <Tooltip title="Премахване на артикулите от списъка">
                        <ClickAwayListener
                            onClickAway={() => {
                                setConfirmDelete(false)
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleMassDelete}
                                color="error"
                                disabled={!state.data?.items?.length || state.loading}
                            >
                                {state.confirmDelete ? 'Потвърждавам' : 'Изтриване'}
                            </Button>
                        </ClickAwayListener>
                    </Tooltip>
                    {state.showGroupTax ?
                            <ClickAwayListener
                                onClickAway={() => {
                                    setShowGroupTax(false)
                                }}
                            >
                                <TextField
                                    type="number"
                                    variant="standard"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    style={{
                                        width: '100px',
                                        marginTop: '10px',
                                        marginLeft: '10px'
                                    }}
                                    autoFocus
                                    name="group_tax"
                                    onChange={handleChange}
                                />
                            </ClickAwayListener>
                            :
                            <>
                            </>
                        }
                        <Tooltip title="Добавяне на групова надценка на всички артикуи от списъка">
                            <Button
                                variant="contained"
                                onClick={() => {
                                    if (!state.showGroupTax) setShowGroupTax(true)
                                    else handleAddGroupTax()
                                }}
                                color="secondary"
                                disabled={!state.data?.items?.length}
                            >
                                {!state.showGroupTax ? 'Надценка' : 'Добавяне'}
                            </Button>
                        </Tooltip>
                    <Tooltip title="Публикуване на списъка с артикули в eBay">
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!state.data?.items?.length}
                            onClick={handlePublish}
                        >
                            Публикуване
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </main>
    )
}

export default CurrentProducts